import styled from 'styled-components';
import { font } from '../../atoms/typography';

export const colorTheme = {
  White: 'var(--white-color)',
  Blue: '#051fcc',
  Black: '#1D2030',
};

export default styled.section`
  padding: 96px 0;
  @media (max-width: 768px) {
    padding: 64px 0;
  }
  .stars {
    display: flex;
    margin: 0 auto;
    width: 365px;
    p {
      ${font('overline', 'sm', '700')}
      color: #FFFFFF;
      vertical-align: middle;
      padding-left: 10px;
    }
  }
  .company-logo {
    padding: 0 40px;
    margin-top: 32px;
    display: inline-block;
    @media (max-width: 1200px) {
      padding: 0 20px;
    }
    @media (max-width: 991px) {
      padding: 0 10px;
    }
    img {
      max-width: 266px;
      max-height: 40px;
    }
  }
  .marquee-container {
    max-width: 970px;
    margin: 0 auto;
    margin-top: 32px;
    .company-logo {
      margin-top: 0;
      padding: 0;
      margin: 0 50px;
    }
    .overlay {
      --gradient-color: ${props => colorTheme[props.theme]},
        rgba(255, 255, 255, 0) !important;
    }
  }
  &.bg-white {
    .stars {
      p {
        color: #6f7283;
      }
    }
  }
  &.bg-blue,
  &.bg-black {
    .company-logo {
      img {
        filter: brightness(0) invert(0.8);
      }
    }
  }
`;
